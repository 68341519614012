import { Typography } from '@mui/material'
import LoyaltyHistory from '@eo-storefronts/eo-core/dist/models/LoyaltyHistory'
import { useTranslations } from '~/src/hooks/useTranslations'
import { DateUtils } from '@eo-storefronts/eo-core'

interface Props {
  historyLine: LoyaltyHistory,
}

const LoyaltyHistoryLineUsageDate = ({ historyLine }: Props) => {
  const { t } = useTranslations()

  // Validate expiration date
  if (!historyLine.expirationDate ||
    DateUtils.isAfter(new Date(), new Date(historyLine.expirationDate))) {
    return null
  }

  return (
    <Typography component='h5' variant='subtitle1'>
      {t(
        'profile.loyalty.expires',
        {
          value: DateUtils.fromNow(new Date(historyLine.expirationDate))
        }
      ).toLowerCase()}
    </Typography>
  )
}

export default LoyaltyHistoryLineUsageDate
