import { Box } from '@mui/material'
import LoyaltyContainerLeft from '~/src/components/profile/account/loyalty/loyalty-container/left'
import LoyaltyContainerRight from '~/src/components/profile/account/loyalty/loyalty-container/right'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'

const LoyaltyContainer = () => {
  const loyaltyIsEnabledAndCustomerIsLoggedIn = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const muiTheme = useCustomMuiTheme()

  if (!loyaltyIsEnabledAndCustomerIsLoggedIn) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 7,
        gridTemplateColumns: '1fr 3fr',
        gridTemplateRows: 'max-content',
        gridTemplateAreas: '"left right"',
        width: '80%',
        mx: 'auto',
        my: 0,
        [muiTheme.breakpoints.down('lg')]: {
          width: '100%'
        },
        [muiTheme.breakpoints.down('md')]: {
          gap: 2,
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'auto',
          gridTemplateAreas: '"left" "right"'
        }
      }}
    >
      <LoyaltyContainerLeft sx={{ gridArea: 'left' }} />
      <LoyaltyContainerRight sx={{ gridArea: 'right' }} />
    </Box>
  )
}

export default LoyaltyContainer
