import { alpha, Box } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import LoyaltyHistoryLineDate from '~/src/components/profile/account/loyalty/history/line/LoyaltyHistoryLineDate'
import LoyaltyHistoryLineUsageDate
  from '~/src/components/profile/account/loyalty/history/line/LoyaltyHistoryLineUsageDate'
import LoyaltyHistoryLineUsagePoint
  from '~/src/components/profile/account/loyalty/history/line/LoyaltyHistoryLineUsagePoints'
import LoyaltyHistoryLineType from '~/src/components/profile/account/loyalty/history/line/LoyaltyHistoryLineType'
import LoyaltyHistory from '@eo-storefronts/eo-core/dist/models/LoyaltyHistory'

interface Props {
  historyLine: LoyaltyHistory,
}

const LoyaltyHistoryLine = ({ historyLine }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        p: 1,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: alpha(muiTheme.palette.text.primary, .2),
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box
        className='left-info__container'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'no-wrap',
          justifyContent: 'space-between'
        }}>
        <LoyaltyHistoryLineType historyLine={historyLine}/>

        <LoyaltyHistoryLineDate historyLine={historyLine}/>
      </Box>

      <Box
        className='right-info__container'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'no-wrap',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          gap: 1
        }}>
        <LoyaltyHistoryLineUsagePoint historyLine={historyLine}/>

        <LoyaltyHistoryLineUsageDate historyLine={historyLine}/>
      </Box>
    </Box>
  )
}

export default LoyaltyHistoryLine
