import { Currency, LoyaltyActionTypeEnum } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import LoyaltyHistory from '@eo-storefronts/eo-core/dist/models/LoyaltyHistory'
import { useRecoilValue } from 'recoil'
import { FIRM_CURRENCY_SELECTOR } from '~/src/stores/firm'
import { useMemo } from 'react'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  historyLine: LoyaltyHistory,
}

const LoyaltyHistoryLineUsagePoint = ({ historyLine }: Props) => {
  const { t } = useTranslations()
  const currency = useRecoilValue<Currency | undefined>(FIRM_CURRENCY_SELECTOR)

  const operationSymbol = useMemo(() => {
    switch (historyLine.actionType) {
      case LoyaltyActionTypeEnum.RECEIVED:
      case LoyaltyActionTypeEnum.VOUCHER_RECEIVED:
        return '+'
      case LoyaltyActionTypeEnum.VOUCHER_USED:
        return '-'
    }
  }, [ historyLine.actionType ])

  const valueType = useMemo(() => {
    if (!currency) {
      return null
    }

    return historyLine.type === 'point' ? t('profile.loyalty.type.point').toLowerCase() : currency.symbol
  }, [ historyLine.type, currency ])

  if (!valueType) {
    return null
  }

  return (
    <Typography component='h3' variant='h6'>{operationSymbol} {historyLine.value} {valueType}</Typography>
  )
}

export default LoyaltyHistoryLineUsagePoint
