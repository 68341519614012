import { DateUtils } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import { useMemo } from 'react'
import LoyaltyHistory from '@eo-storefronts/eo-core/dist/models/LoyaltyHistory'

interface Props {
  historyLine: LoyaltyHistory,
}

const LoyaltyHistoryLineDate = ({ historyLine }: Props) => {
  const date = useMemo(() => {
    if (!historyLine.creationDate) {
      return
    }

    return DateUtils.momentFormat(new Date(historyLine.creationDate), 'DD/MM/YYYY')
  }, [ historyLine.creationDate ])

  const time = useMemo(() => {
    if (!historyLine.creationDate) {
      return
    }

    return DateUtils.momentFormat(new Date(historyLine.creationDate), 'hh:mm')
  }, [ historyLine.creationDate ])

  if (!date || !time) {
    return null
  }

  return (
    <Typography component='h5' variant='subtitle1'>{date} @ {time}</Typography>
  )
}

export default LoyaltyHistoryLineDate
