import { Box, SxProps } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import LoyaltyHistory from '~/src/components/profile/account/loyalty/history'
import FirmLoyaltyRewards from '~/src/components/profile/account/loyalty/rewards'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  sx?: SxProps,
}

const LoyaltyContainerRight = ({ sx }: Props) => {
  const { t } = useTranslations()
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      ...sx
    }}>
      <FirmLoyaltyRewards />
      <Box sx={{ gridArea: 'history' }}>
        <Title>{t('profile.loyalty.history')}</Title>
        <LoyaltyHistory />
      </Box>
    </Box>
  )
}

export default LoyaltyContainerRight
