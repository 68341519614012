import { Box, Typography } from '@mui/material'
import LoyaltyHistory from '@eo-storefronts/eo-core/dist/models/LoyaltyHistory'
import { LoyaltyActionTypeEnum } from '@eo-storefronts/eo-core'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  historyLine: LoyaltyHistory,
}

const LoyaltyHistoryLineType = ({ historyLine }: Props) => {
  const { t } = useTranslations()

  const Content = () => {
    switch (historyLine.actionType) {
      case LoyaltyActionTypeEnum.VOUCHER_USED:
        return (
          <Typography component='h3' variant='h6'>{t('profile.loyalty.type.voucherUsed')}:</Typography>
        )
      case LoyaltyActionTypeEnum.VOUCHER_RECEIVED:
        return (
          <Typography component='h3' variant='h6'>{t('profile.loyalty.type.voucherReceived')}:</Typography>
        )
      case LoyaltyActionTypeEnum.RECEIVED:
        return (
          <Typography component='h3' variant='h6'>{t('profile.loyalty.type.received')}:</Typography>
        )
      default:
        return null
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
        alignItems: 'center'
      }}>
      {Content()}
    </Box>
  )
}

export default LoyaltyHistoryLineType
