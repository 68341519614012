import { LoyaltyHistory as LoyaltyHistoryModel } from '@eo-storefronts/eo-core'
import { Alert, Box } from '@mui/material'
import LoyaltyHistoryLine from '~/src/components/profile/account/loyalty/history/line'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CUSTOMER_LOYALTY_HISTORY } from '~/src/stores/loyalty'

const LoyaltyHistory = () => {
  const loyaltyHistory = useEoValue(CUSTOMER_LOYALTY_HISTORY)
  const { t } = useTranslations()

  if (loyaltyHistory.length === 0) {
    return (
      <Alert 
        sx={{ mt: 2 }} 
        severity='info'>
        {t('profile.loyalty.page.noHistory')}
      </Alert>
    )
  }

  return (
    <Box sx={{ mt: 2 }}>
      {loyaltyHistory.map((historyLine: LoyaltyHistoryModel, index: number) => (
        <LoyaltyHistoryLine key={`${historyLine.type}-${historyLine.value}__${index}`} historyLine={historyLine} />
      ))}
    </Box>
  )
}

export default LoyaltyHistory
